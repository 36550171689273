@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

:root {
  --watermark-container-icon-left: 1vmin;
  --watermark-container-icon-bottom: 1vmin;
  --watermark-container-text-left: 1vmin;
  --watermark-container-text-bottom: 1vmin;
  --activity-buttons-top: 1vmin;
  --activity-buttons-gap: 1vmin;
  --ar-button-padding: 1vmin;
  --state-button-padding: 1vmin;
  --state-button-switch-background-margin-horizontal: 1vmin;
  --bar-changeable-min-height-extra: 1vmin;
  --bar-changeable-gap: 1vmin;
  --bar-materials-item-gap: 1vmin;
  --animation-length: 0.3s;
}

@media (orientation: landscape) {
  :root {
    --bar-button-padding: 1vmin;
    --bar-button-right: 1vmin;
    --bar-container-padding-left: 1vmin;
  }

  @media only screen and (max-width: 1000px) {
    :root {
      --container-font-size: 3vmin;
      --bar-changeable-min-item-min-width: 10vw;
      --bar-changeable-min-item-min-height: 8vh;
      --bar-materials-item-img-width: 10vw;
      --bar-materials-item-img-height: 15vh;
    }
  }

  @media only screen and (min-width: 1001px) {
    :root {
      --container-font-size: 2vmin;
      --bar-changeable-min-item-min-width: 8vw;
      --bar-changeable-min-item-min-height: 6vh;
      --bar-materials-item-img-width: 8vw;
      --bar-materials-item-img-height: 8vh;
    }
  }

  @media only screen and (min-width: 2001px) {
    :root {
      --container-font-size: 1vmin;
      --bar-changeable-min-item-min-width: 4vw;
      --bar-changeable-min-item-min-height: 4vh;
      --bar-materials-item-img-width: 4vw;
      --bar-materials-item-img-height: 6vh;
    }
  }

  :root {
    --horizontal-navbar-width: calc(var(--bar-materials-item-img-width) * 2.5);
  }
}

@media (orientation: portrait) {
  :root {
    --horizontal-navbar-width: 0vw;
    --bar-button-padding: 1vmin;
    --bar-button-bottom: 1vmin;
    --bar-container-padding-left: 1vmin;
    --bar-container-gap: 1vmin;
    --bar-materials-gap: 1vmin;
  }

  @media only screen and (max-height: 1000px) {
    :root {
      --container-font-size: 3.5vmin;
      --bar-changeable-min-item-min-width: 20vw;
      --bar-changeable-min-item-min-height: 5vh;
      --bar-materials-item-img-width: 20vw;
      --bar-materials-item-img-height: 7vh;
    }
  }

  @media only screen and (min-height: 1001px) {
    :root {
      --container-font-size: 2.5vmin;
      --bar-changeable-min-item-min-width: 15vw;
      --bar-changeable-min-item-min-height: 5vh;
      --bar-materials-item-img-width: 15vw;
      --bar-materials-item-img-height: 5vh;
    }
  }

  :root {
    --vertical-navbar-height: calc(
      var(--bar-changeable-min-item-min-height) + var(--bar-materials-gap) * 2 + 15px + var(--bar-materials-item-img-height) +
        var(--container-font-size) * 2 + var(--bar-changeable-gap) * 2 + 15px
    );
  }
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
  font-size: var(--container-font-size);
  color: #202020;
}

.activity-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.watermark-container-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  left: var(--watermark-container-icon-left);
  bottom: var(--watermark-container-icon-bottom);
  z-index: 2;
  transition: bottom var(--animation-length) linear;
  color: #878787;
  text-decoration: none;
}

.watermark-container-text {
  position: absolute;
  font-weight: 400;
  left: var(--watermark-container-text-left);
  bottom: var(--watermark-container-text-bottom);
  z-index: 2;
  color: #878787;
  text-decoration: none;
}

.activity-buttons {
  position: absolute;
  top: var(--activity-buttons-top);
  right: calc(var(--horizontal-navbar-width) + 15px);
  left: calc(var(--horizontal-navbar-width) + 15px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--activity-buttons-gap);
  z-index: 2;
}

.ar-button {
  padding: var(--ar-button-padding);
  background-color: #f3f07d;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: black;
  font-size: 16px;
  border-width: 0;
}

model-viewer {
  width: 120px;
  height: 60px;
  position: absolute;
  left: var(--watermark-container-icon-left);
  top: var(--watermark-container-icon-bottom);
  z-index: 2;
  text-decoration: none;
  color: black;
  font-size: 16px;
  border-width: 0;
}

.state-button {
  padding: var(--state-button-padding);
  background-color: #e3e3e3;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}

.state-button-switch-background {
  margin: 0 var(--state-button-switch-background-margin-horizontal);
  width: 30px;
  height: 15px;
  background-color: #c9c9c9;
  border-radius: 15px;
  padding: 3px 17px 3px 4px;
  transition: padding var(--animation-length) linear;
}

.state-button-switch-background-switched {
  padding: 3px 4px 3px 17px;
}

.state-name-disabled {
  transition: color var(--animation-length) linear;
  color: #878787;
}

.state-button-switch-circle {
  width: 9px;
  height: 9px;
  border-radius: 30px;
  background-color: #202020;
}

.bar-changeable {
  min-height: calc(var(--bar-changeable-min-item-min-height) + var(--bar-changeable-min-height-extra) + 15px);
  max-height: calc(var(--bar-changeable-min-item-min-height) + var(--bar-changeable-min-height-extra) * 2 + 15px);
  display: flex;
  align-items: center;
  gap: var(--bar-changeable-gap);
  overflow: auto;
}

.bar-changeable-item {
  min-height: var(--bar-changeable-min-item-min-height);
  min-width: var(--bar-changeable-min-item-min-width);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #f1f1f1;
  cursor: pointer;
}

.bar-changeable-item-active {
  background-color: #fffee7;
  border: solid;
  border-width: 2px;
  border-color: #f3f07d;
}

.bar-materials-item {
  max-width: var(--bar-materials-item-img-width);
  display: flex;
  flex-direction: column;
  gap: var(--bar-materials-item-gap);
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.bar-materials-item-img {
  min-width: var(--bar-materials-item-img-width);
  max-width: var(--bar-materials-item-img-width);
  min-height: var(--bar-materials-item-img-height);
  max-height: var(--bar-materials-item-img-height);
  border-radius: 10px;
  background-color: pink;
}

.bar-materials-item-img-active {
  border: solid;
  border-width: 2px;
  border-color: #f3f07d;
}

.bar-materials-item-img-src {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

@media (orientation: landscape) {
  .watermark-container-icon {
    display: none;
  }

  .bar-button {
    position: absolute;
    padding: var(--bar-button-padding);
    top: 0;
    bottom: 0;
    right: var(--bar-button-right);
    margin: auto 0;
    height: max-content;
    background-color: #f1f1f1;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: right var(--animation-length) linear;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    z-index: 2;
  }

  .bar-button-bar-opened {
    right: calc(var(--horizontal-navbar-width) + var(--bar-button-right) + 15px);
  }

  .bar-container {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding-left: var(--bar-container-padding-left);
    display: flex;
    flex-direction: column;
    background-color: rgba(241, 241, 241, 0.8);
    width: calc(var(--horizontal-navbar-width) + 15px);
    width: calc(var(--horizontal-navbar-width) + 15px);
    transition: width var(--animation-length) linear;
    z-index: 2;
  }

  .bar-materials {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    overflow-y: auto;
  }

  .bar-container-closed {
    width: 0px;
  }
}

@media (orientation: portrait) {
  .watermark-container-text {
    display: none;
  }

  .watermark-container-bar-opened {
    bottom: calc(var(--vertical-navbar-height) + var(--watermark-container-icon-bottom));
  }

  .bar-button {
    position: absolute;
    padding: var(--bar-button-padding);
    left: 0;
    right: 0;
    bottom: var(--bar-button-bottom);
    margin: 0 auto;
    width: max-content;
    background-color: #f1f1f1;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    transition: bottom var(--animation-length) linear;
    font-size: 16px;
    z-index: 2;
  }

  .bar-button-bar-opened {
    bottom: calc(var(--vertical-navbar-height) + var(--bar-button-bottom));
  }

  .bar-container {
    position: absolute;
    padding-left: var(--bar-container-padding-left);
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: var(--bar-container-gap);
    background-color: rgba(241, 241, 241, 0.8);
    max-height: var(--vertical-navbar-height);
    transition: max-height var(--animation-length) linear;
    z-index: 2;
  }

  .bar-materials {
    display: flex;
    overflow: auto;
    gap: var(--bar-materials-gap);
    min-height: calc(var(--bar-materials-item-img-height) * 2 + var(--bar-materials-gap) * 2 + 15px);
    max-height: calc(var(--bar-materials-item-img-height) * 2 + var(--bar-materials-gap) * 2 + 15px);
  }

  .bar-container-closed {
    max-height: 0px;
  }
}

.lds-dual-ring {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 80px;
  height: 80px;
  visibility: visible;
  z-index: 5;
  transition-delay: 1000ms;
}

.lds-dual-ring-hidden {
  transition-delay: 0ms;
  visibility: hidden;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid rgb(70, 187, 255);
  border-color: rgb(70, 187, 255) transparent rgb(70, 187, 255) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.babylonjs-scene {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  visibility: visible;
  z-index: 1;
}

.babylonjs-scene-hidden {
  visibility: hidden;
}
